import { StoreModel } from './index'
import rollbar from '@mortgage-pos/ui/services/rollbar'
import newRelic from '@mortgage-pos/ui/services/newRelic'
import { recursivelyRemoveEmptyValues, setCookie } from '@mortgage-pos/utils'
import { borrowerPortalRequest } from '@mortgage-pos/ui/services/http'

import {
  BankrateAnswers,
  GetAnswersResult,
  QuestionnaireType,
  ApplicationAnswersOutput,
  LoanPurpose,
  DirectMailerAnswers,
  CookieName,
} from '@mortgage-pos/types'

import {
  thunk,
  Thunk,
  action,
  Action,
  thunkOn,
  ThunkOn,
  computed,
  Computed,
} from 'easy-peasy'

import { FEATURE_FLAG_TYPES } from '@mortgage-pos/data'
import { variation } from '@mortgage-pos/ui/services/featureFlags'
import { calculateMaxCashout } from '../utils/maxCashout'

export interface AnswersModel {
  answers: any
  applicationAnswers: ApplicationAnswersOutput
  bankrateAnswers: BankrateAnswers
  directMailerAnswers: DirectMailerAnswers
  isPrefillingAnswers: boolean
  mergedAnswers: Computed
  maxCashoutValue: Computed<AnswersModel, any, StoreModel>
  setAnswers: Action<AnswersModel, object>
  setIsPrefillingAnswers: Action<AnswersModel, boolean>
  setLeadAnswers: Thunk<AnswersModel, object, object, StoreModel>
  setApplicationAnswers: Action<AnswersModel, ApplicationAnswersOutput>
  setBankrateAnswers: Action<AnswersModel, BankrateAnswers>
  setDirectMailerAnswers: Action<AnswersModel, DirectMailerAnswers>
  getAuthenticatedAnswers: ThunkOn<AnswersModel, object, StoreModel>

  /** TEST UTILITIES **/
  resetAnswers: Action<AnswersModel>
  resetApplicationAnswers: Action<AnswersModel>
  resetBankrateAnswers: Action<AnswersModel>
}

const answers = (): AnswersModel => {
  return {
    answers: {},

    applicationAnswers: {},

    bankrateAnswers: {},

    directMailerAnswers: {},

    isPrefillingAnswers: null,

    mergedAnswers: computed((state: AnswersModel) => {
      return {
        ...state.bankrateAnswers,
        ...state.applicationAnswers,
        ...state.directMailerAnswers,
        ...state.answers,
      }
    }),

    maxCashoutValue: computed(
      [(state) => state.mergedAnswers],
      (mergedAnswers) => {
        const { estimatedValue, remainingBalance, propertyType, propertyUse } =
          mergedAnswers
        return calculateMaxCashout({
          estimatedValue,
          remainingBalance,
          propertyType,
          propertyUse,
        })
      }
    ),

    setIsPrefillingAnswers: action((state, payload) => {
      state.isPrefillingAnswers = payload
    }),

    setAnswers: action((state, payload) => {
      state.answers = { ...state.answers, ...payload }
    }),

    setLeadAnswers: thunk(
      (
        {
          setBankrateAnswers,
          setApplicationAnswers,
          setAnswers,
          setDirectMailerAnswers,
        },
        leadAnswers: GetAnswersResult,
        { getState, getStoreActions, getStoreState }
      ) => {
        const { answers } = getState()
        const { questionnaireType, slug } = getStoreState().questionnaire
        const { tracking } = getStoreActions()
        const { setApplicationId } = getStoreActions().application
        const initialAnswers: any = {}

        if (leadAnswers.bankrateAnswers) {
          const bankrateAnswers = leadAnswers.bankrateAnswers

          // Rely on AVM for estimated value instead
          if (questionnaireType === QuestionnaireType.NTL) {
            delete bankrateAnswers.estimatedValue
          }

          setBankrateAnswers(bankrateAnswers)
          initialAnswers.createAccount = [{ email: bankrateAnswers.email }]
          initialAnswers.declarationMilitary =
            bankrateAnswers.declarationMilitary
          tracking.identify()
        }

        if (leadAnswers.applicationAnswers) {
          const applicationAnswers = recursivelyRemoveEmptyValues(
            leadAnswers.applicationAnswers
          )

          setApplicationId(applicationAnswers.applicationId)
          setApplicationAnswers(applicationAnswers)
        }

        if (leadAnswers.directMailerAnswers) {
          const directMailerAnswers = leadAnswers.directMailerAnswers
          setDirectMailerAnswers(directMailerAnswers)
        }

        if (!answers.loanPurpose) {
          initialAnswers.loanPurpose =
            leadAnswers.applicationAnswers?.loanPurpose ||
            leadAnswers.bankrateAnswers?.loanPurpose ||
            getDefaultLoanPurpose(slug)
        }

        if (Object.keys(initialAnswers).length) {
          setAnswers(initialAnswers)
        }
      }
    ),

    setApplicationAnswers: action((state, applicationAnswers) => {
      state.applicationAnswers = applicationAnswers
    }),

    setBankrateAnswers: action((state, bankrateAnswers) => {
      const { id, ...data } = bankrateAnswers
      state.bankrateAnswers = data
    }),

    setDirectMailerAnswers: action((state, directMailerAnswers) => {
      state.directMailerAnswers = directMailerAnswers
    }),

    getAuthenticatedAnswers: thunkOn(
      (_, storeActions) => storeActions.authentication.setIsAuthenticated,
      async (
        actions,
        setAuthedListener,
        { getState, getStoreState, getStoreActions }
      ) => {
        if (setAuthedListener.payload !== true) {
          actions.setIsPrefillingAnswers(false)
          return
        }

        const { isPhoneFlow, isBrProFlow } = getStoreState().questionnaire
        const isPhoneFlowEnabled = await variation(
          FEATURE_FLAG_TYPES.PHONE_FLOW_ENABLED
        )
        const isPhoneApp = isPhoneFlow && isPhoneFlowEnabled

        // Don't make prefill call for first time users after account creation when setIsAuthenticated is called
        // Unless it's a phone app or from Bankrate Pro
        if (
          getStoreState().pageSections.pageSectionsList.includes(
            'Create Account'
          ) &&
          !isPhoneApp &&
          !isBrProFlow
        ) {
          actions.setIsPrefillingAnswers(false)
          return
        }

        actions.setIsPrefillingAnswers(true)

        let response
        try {
          response = await borrowerPortalRequest('/prefill')
        } catch (e) {
          rollbar.error('Failed to prefill answers for authenticated user', e)
          newRelic.increment('application_answers_fetch.failure')

          actions.setIsPrefillingAnswers(false)

          return
        }

        const {
          applicationId,
          applicationGuid,
          coBorrowerId,
          applicationCreatedAt,
          auth0Id,
        } = response.data
        const {
          setApplicationId,
          setApplicationGuid,
          setCoBorrowerId,
          setApplicationCreatedAt,
        } = getStoreActions().application

        if (applicationId) {
          setApplicationId(applicationId)
        }

        if (applicationGuid) {
          setApplicationGuid(applicationGuid)
        }

        if (coBorrowerId) {
          setCoBorrowerId(coBorrowerId)
        }

        if (applicationCreatedAt) {
          setApplicationCreatedAt(new Date(applicationCreatedAt))
        }

        if (auth0Id) {
          setCookie(CookieName.UserId, auth0Id)
        }

        actions.setAnswers(response.data)
        actions.setIsPrefillingAnswers(false)
        newRelic.increment('application_answers_fetch.success')
      }
    ),

    /** TEST UTILITIES **/

    resetAnswers: action((state) => {
      state.answers = {}
    }),

    resetApplicationAnswers: action((state) => {
      state.applicationAnswers = {}
    }),

    resetBankrateAnswers: action((state) => {
      state.bankrateAnswers = {}
    }),
  }
}

function getDefaultLoanPurpose(slug) {
  return slug?.indexOf('purchase') > -1
    ? LoanPurpose.Purchase
    : LoanPurpose.Refinance
}

export default answers
