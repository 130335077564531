import { ApplicationRevision, Rate, LoanOfficer } from '@mortgage-pos/types'

export enum SECTIONS {
  ProposedTerms = 'Proposed Loan Terms',
  EstimatedCosts = 'Estimated Loan Costs',
  SummaryOfCC = 'Summary of Costs',
  Monthly = 'Estimated Monthly Payment',
  Prepaid = 'Estimated Prepaid Items/Reserves',
}

export interface LoanEstimateRecord {
  latestRevision?: ApplicationRevision
  loanOfficer?: LoanOfficer
  rate?: PricingRate
}

export interface PricingRate extends Rate {
  address?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
}

export interface LoanMetadata {
  loanPurpose?: string
  name?: string
  address?: string
  city?: string
  state?: string
  zip?: string
  propertyValue?: string
  loanOfficer?: {
    name?: string
    nmls?: string
    phone?: string
    email?: string
  }
}

export interface LoanEstimateParameter {
  loanEstimateRecord: LoanEstimateRecord
}
